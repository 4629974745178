import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import getPageTitle from "@/utils/getTitle";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/SiteOverview/home'
    }, {
        path: '/SiteOverview/home',
        name: '首页',
        component: () => import('@/views/home.vue')
    }, {
        path: '/SiteOverview/personnel',
        name: '人员动态',
        // component: () => import('@/views/personnel/index.vue'),
        children: [{
            path: '/SiteOverview/personnel/statistics',
            name: '人员统计',
            component: () => import('@/views/personnel/statistics.vue'),
        }, {
            path: '/SiteOverview/personnel/QRcode',
            name: '人员二维码',
            component: () => import('@/views/personnel/QRcode.vue'),
        }, {
            path: '/SiteOverview/personnel/position',
            name: '人员定位',
            component: () => import('@/views/personnel/position.vue'),
        }, {
            path: '/SiteOverview/personnel/training',
            name: '教育培训',
            component: () => import('@/views/personnel/training.vue'),
        }, {
            path: '/SiteOverview/personnel/safetyAnalysis',
            name: '安管分析',
            component: () => import('@/views/personnel/safetyAnalysis.vue'),
        }],
    }, {
        path: '/SiteOverview/inspect',
        name: '隐患分析',
        children: [{
            path: '/SiteOverview/inspect/checklist',
            name: '项目检查',
            component: () => import('@/views/hiddenDangerAnalysis/checklist.vue'),
        }, {
            path: '/SiteOverview/inspect/randomClap',
            name: '随手拍',
            component: () => import('@/views/hiddenDangerAnalysis/randomClap.vue'),
        }, {
            path: '/SiteOverview/inspect/mobileInspe',
            name: '移动巡检',
            component: () => import('@/views/hiddenDangerAnalysis/mobileInspe.vue'),
        }, {
            path: '/SiteOverview/inspect/spotCheck',
            name: '监督抽查',
            component: () => import('@/views/hiddenDangerAnalysis/spotCheck.vue'),
        }, {
            path: '/SiteOverview/inspect/supervisionAnalysis',
            name: '监理检查',
            component: () => import('@/views/hiddenDangerAnalysis/supervisionAnalysis.vue'),
        }, {
            path: '/SiteOverview/inspect/safetyAnalysis',
            name: '1安管分析',
            component: () => import('@/views/hiddenDangerAnalysis/safetyAnalysis.vue'),
        }, {
            path: '/SiteOverview/inspect/securityLog',
            name: '安全日志',
            component: () => import('@/views/hiddenDangerAnalysis/securityLog.vue'),
        }],
    }, {
        path: '/SiteOverview/dangerous',
        name: '危大工程',
        // component: () => import('@/views/dangerous/index.vue'),
        children: [{
            path: '/SiteOverview/dangerous/towerCrane',
            name: '塔吊',
            component: () => import('@/views/dangerous/towerCrane.vue'),
        }, {
            path: '/SiteOverview/dangerous/elevator',
            name: '升降机',
            component: () => import('@/views/dangerous/elevator.vue'),
        }, {
            path: '/SiteOverview/dangerous/unloadingPlatform',
            name: '卸料平台',
            component: () => import('@/views/dangerous/unloadingPlatform.vue'),
        }, {
            path: '/SiteOverview/dangerous/deepPit',
            name: '深基坑',
            component: () => import('@/views/dangerous/deepPit.vue'),
        }, {
            path: '/SiteOverview/dangerous/formWork',
            name: '高支模',
            component: () => import('@/views/dangerous/formWork.vue'),
        }, {
            path: '/SiteOverview/dangerous/edge',
            name: '临边防护',
            component: () => import('@/views/dangerous/edge.vue'),
        },
        // 围堰监测 deepPit
        {
            path: '/SiteOverview/dangerous/cofferdamMonitoring',
            name: '围堰监测',
            component: () => import('@/views/dangerous/cofferdamMonitoring.vue'),
        }
    ],
    }, {
        path: '/SiteOverview/dustControl',
        name: '绿色施工',
        // component: () => import('@/views/fugitiveDust/index.vue'),
        children: [{
            path: '/SiteOverview/fugitiveDust/environmentalMonitoring',
            name: '环境监测',
            component: () => import('@/views/fugitiveDust/environmentalMonitoring.vue'),
        }, {
            path: '/SiteOverview/fugitiveDust/vehicleFlushing',
            name: '车辆未冲洗',
            component: () => import('@/views/fugitiveDust/vehicleFlushing.vue'),
        }, {
            path: '/SiteOverview/fugitiveDust/noSafetyHat',
            name: '安全帽未佩戴',
            component: () => import('@/views/fugitiveDust/noSafetyHat.vue'),
        }, {
            path: '/SiteOverview/fugitiveDust/reflectiveVest',
            name: '反光背心',
            component: () => import('@/views/fugitiveDust/reflectiveVest.vue'),
        }, {
            path: '/SiteOverview/fugitiveDust/bareSoilCover',
            name: '裸土覆盖',
            component: () => import('@/views/fugitiveDust/bareSoilCover.vue'),
        },
        {
            path: '/SiteOverview/fugitiveDust/openFlame',
            name: '明烟明火',
            component: () => import('@/views/fugitiveDust/openFlame.vue'),
        },
        {
            path: '/SiteOverview/fugitiveDust/regionalRoute',
            name: '危险区域入侵',
            component: () => import('@/views/fugitiveDust/regionalRoute.vue'),
        },
        {
            path: '/SiteOverview/fugitiveDust/towerCraneDriver',
            name: '塔吊司机违规操作',
            component: () => import('@/views/fugitiveDust/towerCraneDriver.vue'),
        },
        {
            path: '/SiteOverview/fugitiveDust/numberOfElevators',
            name: '升降机人数监测',
            component: () => import('@/views/fugitiveDust/numberOfElevators.vue'),
        },
        {
            path: '/SiteOverview/fugitiveDust/waterLevelMonitoring',
            name: '水位点监测',
            component: () => import('@/views/fugitiveDust/waterLevelMonitoring.vue'),
        }],
    }, {
        path: '/SiteOverview/videoMonitor',
        name: '视频监控',
        // component: () => import('@/views/videoMonitor/index.vue'),
        children: [{
            path: '/SiteOverview/videoMonitor/videoSurveillance',
            name: '视频监控',
            component: () => import('@/views/videoMonitor/videoSurveillance.vue'),
        },{
            path: '/SiteOverview/videoMonitor/videoRecording',
            name: '视频记录',
            component: () => import('@/views/videoMonitor/videoRecording.vue'),
        }],
    }, {
        path: '/SiteOverview/mapShow',
        name: '数字工地',
        component: () => import('@/views/digitalSite/index.vue')
    }, {
        path: '/SiteOverview/intelligentMonitoring',
        name: '智能监测',
        children: [{
            path: '/SiteOverview/intelligentMonitoring/intelligentWaterMeter',
            name: '智能水表',
            component: () => import('@/views/intelligentMonitoring/intelligentWaterMeter.vue')
        }, {
            path: '/SiteOverview/intelligentMonitoring/smartMeter',
            name: '智能电表',
            component: () => import('@/views/intelligentMonitoring/smartMeter.vue')
        }, {
            path: '/SiteOverview/intelligentMonitoring/concreteTempe',
            name: '混凝土温度监测',
            component: () => import('@/views/intelligentMonitoring/concreteTempe.vue')
        }, {
            path: '/SiteOverview/intelligentMonitoring/dredgingManage',
            name: '清淤管理',
            component: () => import('@/views/intelligentMonitoring/dredgingManage.vue')
        },{
            path: '/SiteOverview/intelligentMonitoring/towerDetection',
            name: '群塔监测',
            component: () => import('@/views/intelligentMonitoring/towerDetection.vue')
        }],
    }, {
        path: '/SiteOverview/warningCenter',
        name: '预警中心',
        component: () => import('@/views/warningCenter/index.vue')
    }, {
        path: '/SiteOverview/safetyMonitoring',
        name: '安全监测',
        component: () => import('@/views/safetyMonitoring/index.vue')
    },
    {
        path: '/SiteOverview/qualityAssurance',
        name: '质量管理',
        // component: () => import('@/views/qualityAssurance/index.vue'),
        children: [{
            path: '/SiteOverview/qualityAssurance/qualityAssurance',
            name: '质量管理',
            component: () => import('@/views/qualityAssurance/qualityAssurance.vue'),
        }, {
            path: '/SiteOverview/qualityAssurance/process',
            name: '工序报验',
            component: () => import('@/views/qualityAssurance/process.vue'),
        }],
    }

]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.afterEach(async (to, from, next) => {
    document.title = getPageTitle(JSON.parse(String(localStorage.getItem("projectInfo"))).projectName);
});

export default router
